import { BaseModel } from '../../basemodel.model';

export class User extends BaseModel {
    email: string;
    username: string;
    firstName: string;
    firstNome: string;
    fullName: string;
    lastName: string;
    isAdmin: boolean;
    arcadDoctorId: boolean;
    accessToken: string;
    refreshToken: string;
    remember: boolean;
    role: string;
    active: string;
    alfacodFlag: boolean;
    activeValue: string;
    executiveArea: string;
    idAdmin: number;
    language: string;
    idDistributor: number;
    idSuperAgent: number;
    idExecutive: number;
    idAgent: number;
    userGroup: string;
    addresses: Address[];
    vat: any;
    photoFileName: string;
    photoFullUrl: string;
    idUser: string;
    profileComplete: boolean;
    needToBeApproved: boolean;
    question1: string;
    question11: string;
    question2: string;
    question3: string;
    policyConfirmed: boolean;
    policyConfirmedDate: Date;
    isNotificationWasShownPerLogin: boolean;
    whatsAppFlag: boolean;
    arcadFlag: boolean;
    clinicPreferences: any;
    whatsAppCountryCode: string;
    whatsAppDialCode: string;
    whatsAppNumber: string;
    showStepsWarning: boolean;
    showNuvolaJunior: boolean;
    clinicPreferencesUpdated: boolean;

    constructor(params: any) {
        super(params.id);
        this.username = params.username;
        this.firstName = params.firstName;
        this.firstNome = params.firstNome;
        this.lastName = params.lastName;
        this.fullName = params.fullName;
        this.isAdmin = params.isAdmin;
        this.accessToken = params.accessToken;
        this.refreshToken = params.refreshToken;
        this.role = params.role;
        this.executiveArea = params.executiveArea;
        this.idAdmin = params.idAdmin;
        this.language = params.language;
        this.idDistributor = params.idDistributor;
        this.idSuperAgent = params.idSuperAgent;
        this.idAgent = params.idAgent;
        this.vat = params.vat;
        this.email = params.email;
        this.addresses = [];
        this.idExecutive = params.idExecutive;
        this.userGroup = params.userGroup;
        this.photoFullUrl = params.photoFullUrl;
        this.photoFileName = params.photoFileName;
        if (params.addresses) {
            this.addresses = params.addresses;
        }
        this.idUser = params.idUser
        this.profileComplete = params.profileComplete;
        this.question1 = params.question1;
        this.question11 = params.question11;
        this.question2 = params.question2;
        this.question3 = params.question3;
        this.active = params.active;
        this.policyConfirmed = params.policyConfirmed;
        this.policyConfirmedDate = params.policyConfirmedDate;
        this.isNotificationWasShownPerLogin = false;
        this.whatsAppFlag = params.whatsAppFlag;
        this.arcadFlag = params.arcadFlag;
        this.alfacodFlag = params.alfacodFlag;
        this.clinicPreferences = params.clinicPreferences;
        this.whatsAppCountryCode = params.whatsAppCountryCode;
        this.whatsAppDialCode = params.whatsAppDialCode;
        this.whatsAppNumber = params.whatsAppNumber;
        this.showStepsWarning = params.showStepsWarning;
        this.showNuvolaJunior = params.showNuvolaJunior;
        this.clinicPreferencesUpdated = params.clinicPreferencesUpdated;
    }
}

export class Address {
    idAddress: number;
    activityName: string;
    phone: string;
    address: string;
    zipCode: string;
    type: string;
    typeValue: string;
}

export const AddressTypes = [
    {
        key: "R",
        value: "REGISTRATION.returnTypeValue"
    },
    {
        key: "S",
        value: "REGISTRATION.shipTypeValue"
    },
    {
        key: "I",
        value: "REGISTRATION.invoiceTypeValue"
    }
];
